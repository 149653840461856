
import { defineComponent } from "vue";
export default defineComponent({
  name: "VItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    canView: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
});
