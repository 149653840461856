import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-header-title" }
const _hoisted_4 = {
  class: "card-text",
  style: {"text-align":"center"}
}
const _hoisted_5 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.summary), 1)
    ]),
    _createElementVNode("footer", _hoisted_5, [
      (_ctx.canView)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "card-footer-item",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('view')))
          }, "View"))
        : _createCommentVNode("", true),
      (_ctx.canEdit)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "card-footer-item",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('edit')))
          }, "Edit"))
        : _createCommentVNode("", true),
      (_ctx.canDelete)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: "card-footer-item",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('delete')))
          }, "Delete"))
        : _createCommentVNode("", true)
    ])
  ]))
}