
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import VItem from "@/components/list/VItem.vue";
import { Strings } from "@/resources";

export default defineComponent({
  name: "VListApiKeys",
  components: {
    VItem,
  },
  setup() {
    const store = useStore();
    store.dispatch("getApiKeys");

    return {
      apiKeys: ref(computed(() => store.state.apiKeys)),
    };
  },
  methods: {
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    viewApiKey(index: number): void {
      if (this.loading("getApiKeys")) {
        return;
      }

      this.$store.dispatch("setApiKey", this.apiKeys[index]);
      this.$store.dispatch("setModal", "VViewApiKey");
    },
  },
});
